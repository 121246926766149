import React, { FC, useContext } from "react";
import { LangContext } from "../context";
import Text from "./Text";
import { LangPrefix } from "../enums";

export interface TitleProps {
  text: string;
  shadow?: boolean;
  className?: string;
}

export const Title: FC<TitleProps> = ({
  text,
  shadow = true,
  className = "",
}: TitleProps) => {
  const { langPrefix } = useContext(LangContext);
  return (
    <div className="m-3 w-full">
      <Text.Title
        className={`text-3xl md:text-4xl text-center w-full ${
          langPrefix === LangPrefix.Ko ? "mb-3 mt-1" : "mb-1 font-bold"
        } ${shadow ? "text-shadow" : "text-less-shadow"} ${
          langPrefix === LangPrefix.Cht ? "not-italic" : ""
        } ${className}`}
      >
        {text}
      </Text.Title>
      {langPrefix !== LangPrefix.Ko && (
        <Text.Title className="text-sm md:text-base text-gray-common text-center">
          Walkthrough made by Yujin
        </Text.Title>
      )}
    </div>
  );
};
