import React, { FC, HTMLAttributes, useContext } from "react";
import { LoadingContext } from "../context";

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {}

export const Body: FC<BodyProps> = ({ children, className = "" }) => {
  const { loading } = useContext(LoadingContext);

  return loading ? null : (
    <div className="h-full w-full flex flex-col items-center px-5 z-30 relative overflow-y-auto">
      <div
        className={`max-w-3xl w-full flex flex-col items-center pb-24 ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
