import React, { FC, useContext } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "../hooks";
import { LangContext } from "../context";
import { LangPrefix } from "../enums";

export interface SeoProps {
  title?: string;
  description?: string;
  image?: string;
}

export const Seo: FC<SeoProps> = ({ title, description, image }) => {
  const { pathname } = useLocation();
  const { langPrefix } = useContext(LangContext);
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
            defaultImage: image
          }
        }
      }
    `
  );

  const { siteUrl, defaultImage } = site.siteMetadata;
  const titleTemplate =
    langPrefix === LangPrefix.Ko
      ? "%s | 러브언홀릭 공략"
      : "%s | LoveUnholyc Walkthrough";
  const defaultTitle =
    langPrefix === LangPrefix.Ko
      ? "러브언홀릭 공략"
      : "LoveUnholyc Walkthrough";
  const defaultDescription =
    langPrefix === LangPrefix.Ko
      ? "러브언홀릭 공략"
      : "LoveUnholyc Walkthrough made by Yujin";

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={title ? titleTemplate : undefined}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};
