import React, { FC } from "react";
import {
  HorizontalDirection,
  VerticalDirection,
  BackgroundKey,
} from "../enums";
import homeImg from "../assets/images/home.webp";
import commonImg from "../assets/images/common.webp";
import hiImg from "../assets/images/hi.webp";
import leoImg from "../assets/images/leo.webp";
import solImg from "../assets/images/sol.webp";
import solIfImg from "../assets/images/sol-if.webp";
import williamImg from "../assets/images/william.webp";
import eaterImg from "../assets/images/eater.webp";
import shalloteImg from "../assets/images/shallote.webp";
import s3Img from "../assets/images/s3-prologue.webp";
import neverForgetImg from "../assets/images/never-forget.webp";

export interface BackgroundProps {
  className?: string;
  image: string;
  horizontalSend?: HorizontalDirection;
  verticalSend?: VerticalDirection;
  hMin?: number;
  wMin?: number;
  bgColor?: string;
  bilayer?: boolean;
}

export const Background: FC<BackgroundProps> = ({
  className = "",
  image,
  horizontalSend = HorizontalDirection.Center,
  verticalSend = VerticalDirection.Center,
  hMin,
  wMin,
  bgColor,
  bilayer,
}) => (
  <div className="fixed z-0 left-0 md:left-56 right-0 h-screen">
    <div
      className={`relative w-full h-full flex ${
        horizontalSend === HorizontalDirection.Left
          ? "justify-start"
          : horizontalSend === HorizontalDirection.Center
          ? "justify-center"
          : "justify-end"
      }`}
    >
      {bilayer ? (
        <div className="absolute inset-0 filter blur">
          <img
            className={`w-full h-full object-cover ${className}`}
            src={image}
            loading="lazy"
          />
        </div>
      ) : (
        <div className={`absolute inset-0 ${bgColor}`} />
      )}
      <div
        className={`w-full h-full flex flex-col ${
          verticalSend === VerticalDirection.Top
            ? "justify-start"
            : verticalSend === VerticalDirection.Center
            ? "justify-center"
            : "justify-end"
        }`}
        style={hMin ? { maxWidth: `${100 * hMin}vh` } : {}}
      >
        <div
          className="relative w-full bg-white z-10"
          style={
            wMin
              ? { paddingTop: `min(${100 / wMin}%, 100vh)` }
              : { height: "100%" }
          }
        >
          <div className="absolute inset-0 z-20">
            <img
              className={`w-full h-full object-cover ${className}`}
              src={image}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const HomeBackground = () => (
  <Background
    image={homeImg}
    className="opacity-10 object-center"
    hMin={1}
    bilayer
  />
);

export const CommonBackground = () => (
  <Background
    image={commonImg}
    className="opacity-15 object-top"
    verticalSend={VerticalDirection.Bottom}
    wMin={2 / 3}
  />
);

export const HiBackground = () => (
  <Background
    image={hiImg}
    className="opacity-15 object-right"
    verticalSend={VerticalDirection.Bottom}
    wMin={1 / 2}
    bilayer
  />
);

export const LeoBackground = () => (
  <Background
    image={leoImg}
    className="opacity-15 object-left"
    verticalSend={VerticalDirection.Bottom}
    bilayer
    wMin={3 / 5}
  />
);

export const SolBackground = () => (
  <Background
    image={solImg}
    className="opacity-15 object-right"
    verticalSend={VerticalDirection.Bottom}
    wMin={1 / 2}
    bilayer
  />
);

export const SolIfBackground = () => (
  <Background
    image={solIfImg}
    className="opacity-15 object-center"
    verticalSend={VerticalDirection.Bottom}
    wMin={1 / 2}
    bilayer
  />
);

export const WilliamBackground = () => (
  <Background
    image={williamImg}
    className="opacity-15 object-top"
    verticalSend={VerticalDirection.Center}
    hMin={5 / 4}
    bilayer
  />
);

export const EaterBackground = () => (
  <Background
    image={eaterImg}
    className="opacity-15 object-top"
    verticalSend={VerticalDirection.Top}
    hMin={1}
    bilayer
  />
);

export const ShalloteBackground = () => (
  <Background
    image={shalloteImg}
    className="opacity-15 object-center"
    hMin={4 / 5}
    bilayer
  />
);

export const S3PrologueBackground = () => (
  <Background
    image={s3Img}
    className="opacity-15 object-top"
    hMin={1}
    bgColor="bg-black bg-opacity-15"
  />
);

export const NeverForgetBackground = () => (
  <Background
    image={neverForgetImg}
    className="opacity-15 object-center"
    wMin={1 / 2}
    bgColor="bg-black bg-opacity-15"
  />
);

export interface BackgroundFromKeyProps {
  k: BackgroundKey;
}

export const BackgroundFromKey: FC<BackgroundFromKeyProps> = ({ k }) => {
  switch (k) {
    case BackgroundKey.Common:
      return <CommonBackground />;
    case BackgroundKey.Hi:
      return <HiBackground />;
    case BackgroundKey.Leo:
      return <LeoBackground />;
    case BackgroundKey.Sol:
      return <SolBackground />;
    case BackgroundKey.SolIf:
      return <SolIfBackground />;
    case BackgroundKey.William:
      return <WilliamBackground />;
    case BackgroundKey.Eater:
      return <EaterBackground />;
    case BackgroundKey.Shallote:
      return <ShalloteBackground />;
    case BackgroundKey.EaterPrologue:
      return <S3PrologueBackground />;
    case BackgroundKey.NeverForget:
      return <NeverForgetBackground />;
    default:
      return <></>;
  }
};
